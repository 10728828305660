import React from "react";
import { Column, Row } from "../../helpers/layout";
import { ImageField } from "./ImageField";
import Paddings from "../../components/Paddings";
import { FastField } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";

export const BlockImageField = (props: { name: string }) => {
  return (
    <Column>
      <Row>
        <Paddings prefix={props.name} />
      </Row>

      <ImageField name={`${props.name}.image`} label="Image" related />

      <FastField
        name={`${props.name}.link`}
        label="Link"
        fullWidth
        component={TextField}
        margin="normal"
        variant="outlined"
      />
      <FastField
        name={`${props.name}.new_tab`}
        Label={{ label: "Open link in new tab" }}
        component={CheckboxWithLabel}
      />
      <FastField
        name={`${props.name}.caption`}
        label="Caption"
        fullWidth
        component={TextField}
        margin="normal"
        variant="outlined"
      />
    </Column>
  );
};
