import React from "react";
import { RouteChildrenProps } from "react-router-dom";
import TagsList from "../../components/content-types/TagsList";

const InterviewTagsList = (props: RouteChildrenProps) => {
  return (
    <TagsList
      contentType="interview"
      onCreate={() => props.history.push("/interview-tags/create")}
    />
  );
};

export default InterviewTagsList;
