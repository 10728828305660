import request from "utils/request";
import axios from "axios";
import { Me, UserType } from "types/User";
import {
  getApiUrl,
  getSavedSiteKey,
  getToken,
  saveSiteKey,
} from "utils/multi-auth";
import { SiteKey } from "types/common";

export type LoginValues = { email: string; password: string };

const getAxiosConfig = (siteKey?: SiteKey) => {
  const apiUrl = getApiUrl(siteKey);
  return {
    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

export async function login(
  values: { email: string; password: string },
  siteKey: SiteKey
) {
  const { token, tokenKey } = getToken(siteKey);
  if (!tokenKey) {
    return null;
  }

  if (token) {
    localStorage.removeItem(tokenKey);
  }

  const response = await request<{
    token: string;
    type: UserType;
  }>({
    siteKey,
    method: "POST",
    path: "/api/users/login/",
    body: {
      email: values.email,
      password: values.password,
    },
    onError: (e) => {
      throw e;
    },
  });
  if (
    response.data.type !== UserType.ADMIN &&
    response.data.type !== UserType.BLOGGER
  ) {
    throw new Error("Could not login with the provided credentials");
  }

  if (response?.data?.token) {
    localStorage.setItem(tokenKey, response.data.token);
    saveSiteKey(siteKey);
  }
}

export function logout(siteKey?: SiteKey) {
  const { tokenKey } = getToken(siteKey);
  const source = getSavedSiteKey();

  if (!tokenKey || !source) {
    return;
  }

  localStorage.removeItem(tokenKey);

  window.location.href = `/login?source=${source}`;
}

export function authorised(siteKey?: SiteKey) {
  const config = getAxiosConfig(siteKey);
  const { token } = getToken(siteKey);

  if (!token) {
    throw new Error("Not Authorized");
  }

  return axios.create({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Token ${token}`,
    },
  });
}

export async function getUserData(siteKey?: SiteKey) {
  const API = authorised(siteKey);
  return await API.request<Me>({
    method: "GET",
    url: "/api/users/me/",
  });
}
