import React, { useState } from "react";
import { CanvasComponent, Align, Position } from "types/Canvas";
import { css } from "styled-components/macro";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Rnd } from "react-rnd";
import { EditorContent } from "components/EditorContent";
import { ConfirmModal } from "modals/ConfirmModal";

type Callback = () => void;

function calculatePosition(align: Align) {
  if (align === "top-left") {
    return css`
      top: 0px;
      left: 0px;
    `;
  }
  if (align === "top-center") {
    return css`
      top: 0px;
      left: 0px;
      right: 0px;
      margin: auto;
    `;
  }
  if (align === "top-right") {
    return css`
      top: 0px;
      right: 0px;
    `;
  }
  if (align === "left-center") {
    return css`
      top: 0px;
      left: 0px;
      bottom: 0px;
      margin: auto;
    `;
  }
  if (align === "right-center") {
    return css`
      top: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
    `;
  }
  if (align === "bottom-right") {
    return css`
      bottom: 0px;
      right: 0px;
    `;
  }
  if (align === "bottom-center") {
    return css`
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: auto;
    `;
  }
  if (align === "bottom-left") {
    return css`
      bottom: 0px;
      left: 0px;
    `;
  }
  if (align === "center-center") {
    return css`
      left: 0px;
      right: 0px;
      top: 0;
      bottom: 0;
      margin: auto;
    `;
  }

  return null;
}

function getAxis(align: Align) {
  if (align === "center-center") {
    return "y";
  }

  if (align === "top-center") {
    return "y";
  }

  if (align === "bottom-center") {
    return "y";
  }

  if (align === "left-center") {
    return "x";
  }

  if (align === "right-center") {
    return "x";
  }

  return "both";
}

export function sanitizePosition(
  position: Position,
  align: Align,
  canvasHeight?: number
) {
  const axis = getAxis(align);

  if (align === "center-center" && canvasHeight) {
    return {
      x: 0,
      y: canvasHeight / 2 - (canvasHeight - position.y),
    };
  }

  if (axis === "y") {
    return {
      x: 0,
      y: position.y,
    };
  }

  if (axis === "x") {
    return {
      x: position.x,
      y: 0,
    };
  }

  return position;
}

export function CanvasItem(props: {
  align: Align;
  item: CanvasComponent;
  onDelete?: (item: CanvasComponent) => void;
  onEdit?: (item: CanvasComponent) => void;
  onDrag?: (item: CanvasComponent) => void;
  onResize?: (item: CanvasComponent) => void;
}) {
  const { item, align } = props;

  const roundButton = css`
    display: inline-block;
    width: 37px;
    height: 37px;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    color: #fff;
    cursor: pointer;
  `;
  const [confirmAction, setConfirmAction] = useState<null | Callback>(null);

  if (!props.onDrag && !props.onResize) {
    return (
      <div
        css={css`
          position: absolute;
          ${calculatePosition(align)};
          width: ${item.size.width}px;
          height: ${item.size.height}px;
          transform: translate(${item.position.x}px, ${item.position.y}px);
          overflow: hidden;
        `}
      >
        {item.text && <EditorContent text={item.text} />}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        css={css`
          position: absolute;
          ${calculatePosition(align)};
          width: ${item.size.width}px;
          height: ${item.size.height}px;
          transform: translate(${item.position.x}px, ${item.position.y}px);

          .canvas-element {
            width: 100%;
            height: 100%;
            border: 2px dashed red;
            box-sizing: border-box;
            overflow: hidden;
          }

          .actions {
            display: none;
          }

          &:hover {
            .canvas-element {
              background: rgba(255, 0, 0, 0.1);
            }

            .actions {
              display: block;
            }
          }
        `}
      >
        <Rnd
          size={{ width: item.size.width, height: item.size.height }}
          position={{
            x: 0,
            y: 0,
          }}
          scale={0.51}
          dragAxis={getAxis(align)}
          cancel=".actions"
          onDragStop={(_e, data) => {
            if (props.onDrag) {
              props.onDrag({
                ...item,
                position: sanitizePosition(
                  {
                    x: item.position.x + data.x,
                    y: item.position.y + data.y,
                  },
                  align
                ),
              });
            }
          }}
          onResizeStop={(e, direction, ref, _delta) => {
            if (props.onResize) {
              props.onResize({
                ...item,
                size: {
                  width: ref.style.width ? parseInt(ref.style.width) : 0,
                  height: ref.style.height ? parseInt(ref.style.height) : 0,
                },
              });
            }
          }}
        >
          <div className="canvas-element">
            <div>{item.text && <EditorContent text={item.text} />}</div>
            <div
              className="actions"
              css={css`
                position: absolute;
                width: 110px;
                top: 10px;
                right: 10px;
                height: 100%;
                text-align: right;
              `}
            >
              {props.onEdit && (
                <div
                  css={css`
                    ${roundButton};
                    background: green;
                  `}
                  onClick={() => {
                    props.onEdit?.(item);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </div>
              )}
              {props.onDelete && (
                <div
                  css={css`
                    ${roundButton};
                    background: red;
                  `}
                  onClick={() => {
                    setConfirmAction(() => {
                      return () => {
                        props.onDelete?.(item);
                      };
                    });
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </div>
              )}
            </div>
          </div>
        </Rnd>
      </div>
      {confirmAction !== null && (
        <ConfirmModal
          confirmAction={confirmAction}
          handleClose={() => {
            setConfirmAction(null);
          }}
          open={true}
          label="Please confirm deletion"
        />
      )}
    </React.Fragment>
  );
}
