import React, { useMemo, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountIcon from "@material-ui/icons/Person";
import { useLayout } from "../context/LayoutContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import { useUserState } from "context/UserContext";
import Logo from "./Logo";
import { SITE_CONFIGS, SITE_KEYS } from "utils/constants";
import { UserType } from "types/User";
import { CheckCircleOutline } from "@material-ui/icons";
import { css } from "styled-components/macro";
import { getSavedSiteKey } from "utils/multi-auth";

const useStyles = makeStyles((theme) => ({
  logotype: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    whiteSpace: "nowrap",
    height: 30,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.modal + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: "rgba(255, 255, 255)",
  },
  headerIconCollapse: {
    color: "white",
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  grow: {
    flexGrow: 1,
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.primary,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export function Header() {
  const { isAuthenticated, userprofile } = useUserState();
  const { toggleDrawer, isDrawerOpened } = useLayout();
  const classes = useStyles();
  const [profileMenu, setProfileMenu] = useState<Element | null>(null);
  const { logout } = useUserState();

  const [sites, currentSite] = useMemo(() => {
    if (!isAuthenticated || userprofile?.type !== UserType.ADMIN) {
      return [[], null];
    }
    return [SITE_KEYS, getSavedSiteKey()];
  }, [isAuthenticated, userprofile]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => toggleDrawer()}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {isDrawerOpened ? (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <div className={classes.logotype}>
          <Logo white />
        </div>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          {sites.map((site) => (
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
              css={css`
                pointer-events: ${currentSite === site ? "none" : "auto"};
                gap: 10px;
              `}
              onClick={() => {
                window.location.href = `/login?source=${site}`;
              }}
              key={site}
            >
              {SITE_CONFIGS[site]?.label}
              {currentSite === site && (
                <CheckCircleOutline className={classes.profileMenuIcon} />
              )}
            </MenuItem>
          ))}
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={() => {
              if (window.confirm("Are you sure you want to sign out?")) {
                logout();
              }
            }}
          >
            <ExitToAppIcon className={classes.profileMenuIcon} /> Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
