import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { getSiteConfig } from "utils/multi-auth";

export function Copyright() {
  const config = getSiteConfig();
  return config?.web && config?.label ? (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright "}&copy;{" "}
      <Link color="inherit" href={config?.web}>
        {config?.label}
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  ) : null;
}
