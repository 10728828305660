import axios from "axios";
import { AxiosError, AxiosRequestConfig, Method } from "axios";
import { getApiUrl, getToken } from "utils/multi-auth";
import { SiteKey } from "types/common";

export const getHeaders = (headers?: Headers, siteKey?: SiteKey) => {
  const { token } = getToken(siteKey);
  let _headers = {};
  if (token) {
    _headers = {
      Authorization: `Token ${token}`,
    };
  }

  if (headers) {
    return {
      ...headers,
      ..._headers,
    };
  }
  return _headers;
};

export type RequestConfig = {
  path: string;
  method?: Method;
  body?: AxiosRequestConfig["data"];
  params?: Record<string, unknown>;
  onError?: (err: AxiosError) => void;
  headers?: AxiosRequestConfig["headers"];
  returnErrors?: boolean;
  siteKey?: SiteKey;
};

const request = async <Data>(config: RequestConfig) => {
  const {
    path,
    method = "GET",
    params,
    body,
    onError,
    headers = {},
    returnErrors = false,
    siteKey,
  } = config;
  if (!path) {
    throw new Error('No "path" argument provided');
  }

  const apiUrl = getApiUrl(siteKey);

  const axiosConfig: AxiosRequestConfig = {
    url: `${apiUrl}${path}`,
    method,
  };

  if (body && ["put", "post", "patch"].includes(method.toLowerCase())) {
    axiosConfig.data = body;
  }

  if (params) {
    axiosConfig.params = params;
  }

  axiosConfig.headers = getHeaders(headers, siteKey);

  const { status, data }: { status: number; data: Data } = await axios
    .request(axiosConfig)
    .catch((err: AxiosError) => {
      if (onError) {
        onError(err);
      }

      return {
        status: err?.response?.status || 500,
        data: returnErrors ? err?.response?.data : null,
      };
    });

  return { status, data };
};

export default request;
