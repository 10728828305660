import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { RouteChildrenProps } from "react-router";
import { Link } from "react-router-dom";
import { useDetail } from "../../useAPI";
import { Interview } from "../../types/Post";
import { updateInterview } from "../../actions/interview/updateInterview";
import { deleteInterview } from "../../actions/interview/deleteInterview";
import ContentTypeForm from "../../components/content-types/ContentTypeForm";

export function InterviewEdit(
  props: RouteChildrenProps<{
    interviewId: string;
  }>
) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const interviewId = props.match!.params.interviewId;
  const { data, error } = useDetail<
    Interview & { interviewee: { label: string; value: number } | null }
  >("interviews", interviewId);
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/interviews"}>Interviews</Link>
        <Typography color="textPrimary">
          {error ? error : data ? data.name : "Loading"}
        </Typography>
      </Breadcrumbs>
      {data && (
        <ContentTypeForm
          initialValues={data}
          contentType="interview"
          action="update"
          onSubmit={async (values) => {
            await updateInterview(interviewId, values);
          }}
          onDelete={async () => {
            await deleteInterview(interviewId);
            props.history.push("/interviews");
          }}
        />
      )}
    </Layout>
  );
}
