import React from "react";
import { css } from "styled-components/macro";
import { getSiteConfig } from "utils/multi-auth";

const Logo = ({ white }: { white?: boolean }) => {
  const config = getSiteConfig();

  return config?.logo ? (
    <img
      src={config.logo}
      alt={`${config.label || ""} Logo`}
      css={css`
        filter: ${white ? "brightness(0) invert(1)" : "none"};
        object-fit: contain;
        width: auto;
        height: 100%;
        max-width: 100%;
      `}
    />
  ) : null;
};

export default Logo;
