import { ContentType } from "../../types/common";
import { authorised } from "../../request";
import { PostTag } from "../../types/PostTag";

export async function createTag(
  data: Omit<PostTag, "id">,
  type: ContentType = "post"
) {
  const API = authorised();
  return await API.request<PostTag>({
    method: "POST",
    url: `/api/admin/blog/${type}-tags/`,
    data,
  });
}
