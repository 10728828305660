import React from "react";
import * as Formik from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { SelectField } from "./fields/SelectField";
import { ImageField } from "./fields/ImageField";
import { Column, Row, Spacing } from "../helpers/layout";
import {
  paddingOptions,
  opacityOptions,
  animationOptions,
} from "../helpers/form";
import { Canvas } from "types/Canvas";
import { Media } from "types/Media";
import { CanvasEditor } from "../components/CanvasEditor";
import { ColorPickerField } from "./fields/ColorPickerField";

export type FormValues = Pick<
  Canvas,
  | "padding"
  | "align"
  | "components"
  | "bg_color"
  | "bg_opacity"
  | "parallax"
  | "animation"
> & {
  image: {
    value: Media;
  } | null;
};

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

const alignOptions = [
  {
    value: "top-left",
    label: "Top left",
  },
  {
    value: "top-center",
    label: "Top center",
  },
  {
    value: "top-right",
    label: "Top right",
  },
  {
    value: "left-center",
    label: "Left center",
  },
  {
    value: "right-center",
    label: "Right center",
  },
  {
    value: "bottom-left",
    label: "Bottom left",
  },
  {
    value: "bottom-center",
    label: "Bottom center",
  },
  {
    value: "bottom-right",
    label: "Bottom right",
  },
  {
    value: "center-center",
    label: "Center center",
  },
];

export function CanvasForm(props: {
  formRef: React.MutableRefObject<any>;
  initialValue: FormValues | null;
  onSubmit: (values: FormValues) => void;
}) {
  return (
    <Formik.Formik<FormValues>
      ref={props.formRef}
      initialValues={
        props.initialValue
          ? props.initialValue
          : {
              image: null,
              align: "top-left",
              parallax: "",
              animation: "",
              bg_color: "#fff",
              bg_opacity: 0,
              padding: {
                top: "none",
                bottom: "none",
                left: "none",
                right: "none",
              },
              components: [],
            }
      }
      validationSchema={Yup.object().shape({
        image: Yup.object().required(),
        align: Yup.string().required(),
        padding: Yup.object().shape({
          top: Yup.string().required(),
          bottom: Yup.string().required(),
          left: Yup.string().required(),
          right: Yup.string().required(),
        }),
      })}
      onSubmit={(values: FormValues) => {
        props.onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Formik.Form>
            <Column gutter={Spacing.xl}>
              <Row align="center">
                <FieldWrapper>
                  <ImageField name={"image"} label="Image" related />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    fullWidth
                    label="Parallax"
                    name={`parallax`}
                    options={[
                      {
                        value: "",
                        label: "None",
                      },
                      {
                        value: "image",
                        label: "Image",
                      },
                      {
                        value: "text",
                        label: "Text",
                      },
                    ]}
                  />
                </FieldWrapper>
                {values.parallax !== "text" && (
                  <FieldWrapper>
                    <SelectField
                      fullWidth
                      label="Animation"
                      name={`animation`}
                      options={animationOptions}
                    />
                  </FieldWrapper>
                )}
                <FieldWrapper>
                  <ColorPickerField name="bg_color" label="Background Color" />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    label="Image Opacity"
                    name={`bg_opacity`}
                    fullWidth
                    options={opacityOptions}
                  />
                </FieldWrapper>
              </Row>

              {values.image && (
                <CanvasEditor
                  canvas={{
                    image: values.image,
                    bg_color: values.bg_color,
                    bg_opacity: values.bg_opacity,
                    parallax: values.parallax || "",
                    animation: values.animation || "",
                    align: values.align,
                    components: values.components || [],
                    padding: values.padding,
                  }}
                  onChangeComponents={(components) => {
                    setFieldValue("components", components);
                  }}
                />
              )}
              <Row>
                <FieldWrapper>
                  <SelectField
                    fullWidth
                    label="Align"
                    name={`align`}
                    options={alignOptions}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    fullWidth
                    label="Padding top"
                    name={`padding.top`}
                    options={paddingOptions}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    fullWidth
                    label="Padding bottom"
                    name={`padding.bottom`}
                    options={paddingOptions}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    fullWidth
                    label="Padding left"
                    name={`padding.left`}
                    options={paddingOptions}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    fullWidth
                    label="Padding right"
                    name={`padding.right`}
                    options={paddingOptions}
                  />
                </FieldWrapper>
              </Row>
            </Column>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
