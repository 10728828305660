import React, { useState, useEffect } from "react";
import { login, logout, getUserData, LoginValues } from "../request";
import { Me } from "types/User";
import { getSiteKey, saveSiteKey } from "utils/multi-auth";

type State = {
  isAuthenticated: boolean | null;
  userprofile: Me | null;
};

const emptyState = {
  isAuthenticated: null,
  userprofile: null,
  login: (_values: LoginValues) => Promise.resolve(),
  logout: () => null,
};

type Context = State & {
  login: (values: LoginValues) => Promise<void>;
  logout: () => void;
};

const UserStateContext = React.createContext<Context>(emptyState);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<State>(emptyState);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const siteKey = getSiteKey(true);
        if (!siteKey) {
          throw new Error("Unknown or missing site key");
        }

        const response = await getUserData(siteKey);
        const userprofile = response.data;

        if (response.status === 200) {
          saveSiteKey(siteKey);
        }

        setState((state) => ({
          ...state,
          userprofile,
          isAuthenticated: true,
        }));
      } catch {
        setState((state) => ({
          ...state,
          userprofile: null,
          isAuthenticated: false,
        }));
      }
    };

    void fetchProfile();
  }, [state.isAuthenticated]);

  const actions = {
    login: async (values: LoginValues) => {
      try {
        const siteKey = getSiteKey();
        if (!siteKey) {
          throw new Error("Unknown or missing site key");
        }

        await login(values, siteKey);

        const response = await getUserData(siteKey);
        const userprofile = response.data;
        setState((state) => ({ ...state, userprofile, isAuthenticated: true }));
      } catch (e: any) {
        setState((state) => ({
          ...state,
          userprofile: null,
          isAuthenticated: false,
        }));

        throw new Error(e.response?.data?.detail || "Something went wrong");
      }
    },
    logout,
  };

  return (
    <UserStateContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useUserState() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }

  return context;
}
