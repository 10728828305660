import React, { useEffect } from "react";

const DD_DRAWER_OPENED = "DD_DRAWER_OPENED";

interface State {
  isDrawerOpened: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
}

const LayoutContext = React.createContext<State>({
  isDrawerOpened: false,
  toggleDrawer: () => null,
  closeDrawer: () => null,
});

export function LayoutProvider({ children }: { children: React.ReactNode }) {
  const localValue = localStorage.getItem(DD_DRAWER_OPENED);
  const [state, setState] = React.useState({
    isDrawerOpened: localValue === "true",
  });

  useEffect(() => {
    localStorage.setItem(
      DD_DRAWER_OPENED,
      state.isDrawerOpened ? "true" : "false"
    );
  }, [state]);

  const actions = {
    toggleDrawer: () => {
      setState((state) => ({
        ...state,
        isDrawerOpened: !state.isDrawerOpened,
      }));
    },
    closeDrawer: () => {
      setState((state) => ({
        ...state,
        isDrawerOpened: false,
      }));
    },
  };

  return (
    <LayoutContext.Provider value={{ ...state, ...actions }}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = React.useContext(LayoutContext);

  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }

  return context;
}
