import React from "react";
import { css } from "styled-components/macro";
import { Spacing } from "../helpers/layout";

export function FormRow(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
      `}
    >
      {props.children}
    </div>
  );
}

export function FormLeftside(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div
      css={css`
        flex: 1 0 50%;
        width: 50%;
        margin-right: ${Spacing.m};
      `}
    >
      {props.children}
    </div>
  );
}

export function FormRightside(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div
      css={css`
        flex: 0 0 280px;
      `}
    >
      <div
        css={css`
          position: sticky;
          top: 70px;
          overflow: scroll;
          max-height: calc(100vh - 70px);
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
