import { useUserState } from "context/UserContext";
import { UserType } from "types/User";
import { getFrontendUrl, getToken } from "utils/multi-auth";

export default function usePreviewUrl() {
  const { userprofile } = useUserState();
  return (url: string) => {
    if (!url) return;
    let previewUrl = getFrontendUrl(url);
    if (userprofile?.type === UserType.BLOGGER) {
      const { token } = getToken();
      previewUrl += `?nonce=${token}`;
    }
    return previewUrl;
  };
}
