import { authorised } from "../../request";
import { ContentType } from "../../types/common";
import { PostTag } from "../../types/PostTag";

export async function updateTag(
  id: string,
  data: Omit<PostTag, "id">,
  type: ContentType = "post"
) {
  const API = authorised();

  return await API.request<PostTag>({
    method: "PATCH",
    url: `/api/admin/blog/${type}-tags/${id}/`,
    data,
  });
}
