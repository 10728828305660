import { authorised } from "../../request";
import { Post } from "../../types/Post";

export async function deletePost(id: string) {
  const API = authorised();

  return await API.request<Post>({
    method: "DELETE",
    url: `/api/admin/blog/posts/${id}/`,
  });
}
