import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import Themes from "./themes";
import { UserProvider } from "./context/UserContext";
import { LayoutProvider } from "./context/LayoutContext";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-sortable-tree/style.css";
import "react-resizable/css/styles.css";
import "./global.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
