/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SiteKey } from "types/common";

type SiteConfig = {
  api: string;
  logo: string;
  web: string;
  label: string;
};

export const SITE_CONFIGS: Record<SiteKey, SiteConfig> = {
  refocus: {
    label: "Refocus Awards",
    web: process.env.REACT_APP_REFOCUS_FRONTEND_URL!,
    api: process.env.REACT_APP_REFOCUS_BACKEND_URL!,
    logo: "https://assets.refocus-awards.com/static_v2/header-logo.svg",
  },
  x1: {
    label: "Exposure One Awards",
    web: process.env.REACT_APP_X1_FRONTEND_URL!,
    api: process.env.REACT_APP_X1_BACKEND_URL!,
    logo: "https://assets.exposureoneawards.com/static_v2/header-logo.svg",
  },
  "1839": {
    label: "1839 Awards",
    web: process.env.REACT_APP_1839_FRONTEND_URL!,
    api: process.env.REACT_APP_1839_BACKEND_URL!,
    logo: "https://assets.1839awards.com/static_v2/header-logo.svg",
  },
};

export const SITE_KEYS = Object.keys(SITE_CONFIGS) as SiteKey[];
export const ACCESS_TOKEN_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY || "";
export const SITE_KEY_LOCAL_STORAGE_KEY = "crc_site_key";
