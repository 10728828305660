import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Formik from "formik";
import { TextField } from "formik-material-ui";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useUserState } from "../context/UserContext";
import * as Yup from "yup";
import { ErrorBoundary } from "react-error-boundary";
import { css } from "styled-components/macro";
import { SITE_CONFIGS, SITE_KEYS } from "utils/constants";
import { getSiteKey, getToken } from "utils/multi-auth";
import Logo from "../components/Logo";
import { Copyright } from "components/Copyright";
import { Link } from "@material-ui/core";

const supportEmail = encodeURIComponent(`Hello,

The login page is not working for me.
This is the link I used to log in: ${window.location.href}`);

interface FormValues {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    height: "100svh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoContainer: {
    maxWidth: "100%",
    height: 70,
  },
  secondaryTitle: {
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderTop: "1px solid #dedede",
    fontSize: 30,
    fontWeight: 700,
    letterSpacing: "0.04em",
    width: "100%",
    textAlign: "center",
    color: "#2A2A2A",
  },
}));

const FsContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100svh;
        gap: 20px;

        span {
          font-size: 40px;
          font-weight: 700;
        }
      `}
    >
      {children}
    </div>
  );
};

const SiteKeyError = () => (
  <FsContainer>
    <span role="img" aria-label="Error">
      ⚠️
    </span>
    <span>Something went wrong</span>
    <a
      href={`mailto:hello@refocus-awards.com?subject=Blog%20Login%20Issue&body=${supportEmail}`}
    >
      Contact us
    </a>
  </FsContainer>
);

const getLoggedInSites = () => {
  const siteKey = getSiteKey();
  return SITE_KEYS.filter((key) => {
    const { token } = getToken(key);
    return !!token && key !== siteKey;
  });
};

const OtherBlogs = () => {
  const otherSites = getLoggedInSites();
  if (!otherSites.length) {
    return null;
  }
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin-top: 30px;
      `}
    >
      <div
        css={`
          display: flex;
          gap: 10px;
          align-items: center;
          width: 100%;
          font-weight: 700;
          letter-spacing: 0.06em;

          &:before,
          &:after {
            content: "";
            flex: 1;
            border-bottom: 1px solid #dedede;
          }
        `}
      >
        <span>OTHER BLOGS</span>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          gap: 20px;
        `}
      >
        {otherSites.map((b) => (
          <span key={b}>
            <Link href={`/login?source=${b}`}>{SITE_CONFIGS[b].label}</Link>
          </span>
        ))}
      </div>
    </div>
  );
};

const LoginContent = () => {
  const classes = useStyles();
  const { login } = useUserState();
  const [error, setError] = useState<string | null>(null);
  const [loginFormVisible, setLoginFormVisible] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const siteKey = getSiteKey();

    if (!siteKey || !SITE_CONFIGS[siteKey]) {
      setLoginFormVisible(false);
    }

    setLoaded(true);
  }, []);

  if (!loaded) {
    return (
      <FsContainer>
        <CircularProgress size={25} />
      </FsContainer>
    );
  }

  if (!loginFormVisible) {
    return <SiteKeyError />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
        <Typography
          component="h1"
          variant="h5"
          className={classes.secondaryTitle}
        >
          BLOG
        </Typography>
        <Formik.Formik<FormValues>
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required(),
            password: Yup.string().required(),
          })}
          onSubmit={async (values: FormValues, { setSubmitting }) => {
            try {
              setSubmitting(true);
              await login(values);
            } catch (error: any) {
              setError(error.toString());
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Formik.Form className={classes.form}>
              <Formik.FastField
                name="email"
                label="Email"
                type="email"
                fullWidth
                component={TextField}
                variant="outlined"
                margin="normal"
              />
              <Formik.FastField
                name="password"
                label="Password"
                type="password"
                fullWidth
                component={TextField}
                variant="outlined"
                margin="normal"
              />
              <FormHelperText error={!!error}>{error}</FormHelperText>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={25} /> : "Sign In"}
              </Button>
            </Formik.Form>
          )}
        </Formik.Formik>
        <Copyright />
        <OtherBlogs />
      </div>
    </Container>
  );
};

export function Login() {
  return (
    <ErrorBoundary fallback={<SiteKeyError />}>
      <LoginContent />
    </ErrorBoundary>
  );
}
