import React from "react";
import { Repeater } from "../../components/Repeater";
import { Block } from "../../types/Block";
import { BlockContentField } from "./BlockContentField";
import { Paper } from "../../components/Paper";
import Typography from "@material-ui/core/Typography";
import { css } from "styled-components/macro";
import { Spacing } from "../../helpers/layout";
// import { SelectField } from "./SelectField";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import uuidv4 from "uuid/v4";
import { QuestionAnswer } from "@material-ui/icons";
// import ViewColumnIcon from "@material-ui/icons/ViewColumn";
// import HomeWorkIcon from "@material-ui/icons/HomeWork";
// import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
// import WallpaperIcon from "@material-ui/icons/Wallpaper";
// import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
// import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
// import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
// import StoreIcon from "@material-ui/icons/Store";
// import TableChartIcon from "@material-ui/icons/TableChart";
// import SeriesIcon from "@material-ui/icons/Theaters";
// import ArtTrackIcon from "@material-ui/icons/ArtTrack";
// import ImportContactsIcon from "@material-ui/icons/ImportContacts";
// import ContactMailIcon from "@material-ui/icons/ContactMail";
// import LinksIcon from "@material-ui/icons/Link";
// import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
// import RemoveIcon from "@material-ui/icons/Remove";
// import HeightIcon from "@material-ui/icons/Height";
// import FaceIcon from "@material-ui/icons/Face";

// const FieldWrapper = styled.div`
//   flex: 0 0 15%;
// `;

const paddingDefaults = {
  top: "xl",
  bottom: "xl",
  left: "none",
  right: "none",
};

const blockOptions: {
  label: string;
  icon?: JSX.Element;
  initialValue: Omit<Block, "uuid">;
}[] = [
  {
    label: "Text",
    icon: <TextFieldsIcon />,
    initialValue: {
      type: "text",
      name: "New block",
      visibility: "all",
      content: {
        padding: { ...paddingDefaults, left: "xxxl", right: "xxxl" },
      },
    },
  },
  {
    label: "Image",
    icon: <CropOriginalIcon />,
    initialValue: {
      type: "image",
      name: "New block",
      visibility: "all",
      content: {
        size: "100",
        new_tab: false,
        link: "",
        padding: paddingDefaults,
        caption: "",
      },
    },
  },
  {
    label: "Questions & Answers",
    icon: <QuestionAnswer />,
    initialValue: {
      type: "qna",
      name: "New block",
      visibility: "all",
      content: {
        items: [],
        padding: { ...paddingDefaults, left: "xxxl", right: "xxxl" },
      },
    },
  },
  {
    label: "Quote",
    icon: <FormatQuoteIcon />,
    initialValue: {
      type: "quote",
      name: "New block",
      visibility: "all",
      content: {
        quote: "",
        padding: paddingDefaults,
      },
    },
  },
  {
    label: "Carousel",
    icon: <ViewCarouselIcon />,
    initialValue: {
      type: "carousel",
      name: "New block",
      visibility: "all",
      content: {
        style: "scroller",
        items: [],
        padding: paddingDefaults,
      },
    },
  },
  // {
  //   label: "Fullscreen Video",
  //   icon: <OndemandVideoIcon />,
  //   initialValue: {
  //     type: "fullscreen_video",
  //     name: "New block",
  //     visibility: "all",
  //     content: {},
  //   },
  // },
  // {
  //   label: "Image + Text",
  //   icon: <ArtTrackIcon />,
  //   initialValue: {
  //     type: "image_text",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       layout: "fullsize",
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Video + Text",
  //   icon: <SubscriptionsIcon />,
  //   initialValue: {
  //     type: "video_text",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       layout: "fullsize",
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //       video_opacity: 1,
  //     },
  //   },
  // },
  // {
  //   label: "Video Grid",
  //   icon: <VideoLibraryIcon />,
  //   initialValue: {
  //     type: "video_grid",
  //     name: "New block",
  //     visibility: "all",
  //     content: {},
  //   },
  // },
  // {
  //   label: "Awards",
  //   icon: <EmojiEventsIcon />,
  //   initialValue: {
  //     type: "awards",
  //     name: "New block",
  //     visibility: "all",
  //     content: {},
  //   },
  // },
  // {
  //   label: "Canvas",
  //   icon: <WallpaperIcon />,
  //   initialValue: {
  //     type: "canvas",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       bg_opacity: 1,
  //       image: {
  //         value: null,
  //       },
  //       parallax: "",
  //       animation: "",
  //       align: "top-left",
  //       bg_color: "#fff",
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Tabs",
  //   icon: <TableChartIcon />,
  //   initialValue: {
  //     type: "tabs",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       tabs: [],
  //     },
  //   },
  // },
  // {
  //   label: "Press",
  //   icon: <ImportContactsIcon />,
  //   initialValue: {
  //     type: "press",
  //     name: "New block",
  //     visibility: "all",
  //     content: {},
  //   },
  // },
  // {
  //   label: "Newsletter",
  //   icon: <ContactMailIcon />,
  //   initialValue: {
  //     type: "newsletter",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       position: "leftside",
  //       title: "",
  //       text: "",
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Image Grid",
  //   icon: <PhotoLibraryIcon />,
  //   initialValue: {
  //     type: "image_grid",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       layout: "columns",
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //       text: "",
  //     },
  //   },
  // },
  // {
  //   label: "Columns",
  //   icon: <ViewColumnIcon />,
  //   initialValue: {
  //     type: "columns",
  //     name: "New block",
  //     visibility: "all",
  //     content: {},
  //   },
  // },
  // {
  //   label: "In Homes",
  //   icon: <HomeWorkIcon />,
  //   initialValue: {
  //     type: "in_homes",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       text: "",
  //       in_homes: {
  //         type: "Related",
  //         value: [],
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Series",
  //   icon: <SeriesIcon />,
  //   initialValue: {
  //     type: "series",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       series: {
  //         type: "Related",
  //         value: [],
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Products",
  //   icon: <StoreIcon />,
  //   initialValue: {
  //     type: "products",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       layout: "masonry",
  //       products: {
  //         type: "Related",
  //         value: [],
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Separator line",
  //   icon: <RemoveIcon />,
  //   initialValue: {
  //     type: "hr",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       size: "",
  //     },
  //   },
  // },
  // {
  //   label: "Spacing",
  //   icon: <HeightIcon />,
  //   initialValue: {
  //     type: "spacing",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       size: "",
  //     },
  //   },
  // },
  // {
  //   label: "Embed Video",
  //   icon: <SubscriptionsIcon />,
  //   initialValue: {
  //     type: "embedvideo",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //     },
  //   },
  // },
  // {
  //   label: "Links",
  //   icon: <LinksIcon />,
  //   initialValue: {
  //     type: "links",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       links: [],
  //     },
  //   },
  // },
  // {
  //   label: "Design Partnerships",
  //   icon: <FaceIcon />,
  //   initialValue: {
  //     type: "designer",
  //     name: "New block",
  //     visibility: "all",
  //     content: {
  //       padding: {
  //         top: "none",
  //         bottom: "none",
  //         left: "none",
  //         right: "none",
  //       },
  //       title: "",
  //       description: "",
  //       designers: [],
  //     },
  //   },
  // },
];

const BlocksFieldItem = React.memo(function (props: {
  name: string;
  idx: number;
  type: string;
}) {
  const { idx } = props;
  const blockSettings = blockOptions.find(
    (block) => block.initialValue.type === props.type
  );

  return (
    <div>
      {blockSettings && (
        <div
          css={css`
            border-bottom: 1px solid #eee;
            padding-bottom: ${Spacing.m};
            margin-bottom: ${Spacing.l};
            display: flex;
            justify-content: space-between;
          `}
        >
          <Typography variant="h5">{blockSettings.label}</Typography>
          {/* <FieldWrapper>
            <SelectField
              fullWidth
              label="Block visibility"
              name={`${props.name}[${idx}].visibility`}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "desktop",
                  label: "Desktop",
                },
                {
                  value: "tablet",
                  label: "Tablet",
                },
                {
                  value: "mobile",
                  label: "Mobile",
                },
              ]}
            />
          </FieldWrapper> */}
        </div>
      )}
      <BlockContentField
        name={`${props.name}[${idx}].content`}
        type={props.type}
      />
    </div>
  );
});

export const BlocksField = React.memo(function (props: { name: string }) {
  return (
    <Repeater
      name={props.name}
      wrapper={Paper}
      enableReordering
      addNewLabel={"New block"}
      options={blockOptions}
      allowDuplicate
      generateNewObject={(option) => {
        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...option!,
          uuid: uuidv4(),
        };
      }}
    >
      {({ idx, value }) => {
        return (
          <BlocksFieldItem name={props.name} idx={idx} type={value[idx].type} />
        );
      }}
    </Repeater>
  );
});
