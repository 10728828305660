import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { AddCircle, FindInPage } from "@material-ui/icons";

import { Layout } from "components/Layout";
import { ListTable } from "components/ListTable";
import { Visibility } from "components/Visibility";
import { Breadcrumbs } from "components/Breadcrumbs";
import { Paper } from "components/Paper";
import { Row } from "helpers/layout";
import usePreviewUrl from "helpers/usePreviewUrl";
import { Interview, Post } from "types/Post";

export function ContentList({
  type = "post",
  onCreate,
}: {
  type: "post" | "interview";
  onCreate: () => void;
}) {
  const plural = type === "post" ? "posts" : "interviews";
  const label = plural.charAt(0).toUpperCase() + plural.slice(1);
  const getPreviewUrl = usePreviewUrl();

  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={onCreate}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<AddCircle />}
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">{label}</Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<typeof type extends "post" ? Post : Interview>
          url={plural}
          enableSearch
          head={[
            { label: "Name" },
            { label: "Slug" },
            ...(type === "interview" ? [{ label: "Interviewee" }] : []),
            { label: "Author" },
            { label: "Visibility", width: "130px" },
            { label: "Actions", align: "right" },
          ]}
        >
          {(result) => (
            <TableRow key={result.id}>
              <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{result.slug}</TableCell>
              {type === "interview" ? (
                <TableCell>
                  {result?.interviewee?.label}{" "}
                  <b>
                    (
                    {result.interviewee_type
                      ? capitalize(result.interviewee_type)
                      : ""}
                    )
                  </b>
                </TableCell>
              ) : null}
              <TableCell>
                {result?.display_author_name ||
                  result.author?.name ||
                  "Admin User"}
              </TableCell>
              <TableCell>
                <Visibility type={result.visibility} />
              </TableCell>
              <TableCell align="right">
                <Row align="center" justify="flex-end">
                  {result?.slug ? (
                    <Fab
                      color="primary"
                      aria-label="preview"
                      size="small"
                      onClick={() =>
                        window.open(
                          getPreviewUrl(`preview/${type}/${result.slug}`),
                          "_blank"
                        )
                      }
                    >
                      <FindInPage />
                    </Fab>
                  ) : null}
                  <Link to={`/${plural}/${result.id}`}>
                    <Fab color="primary" aria-label="edit" size="small">
                      <EditIcon />
                    </Fab>
                  </Link>
                </Row>
              </TableCell>
            </TableRow>
          )}
        </ListTable>
      </Paper>
    </Layout>
  );
}
