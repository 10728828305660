import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { RouteChildrenProps } from "react-router";
import { createInterview } from "../../actions/interview/createInterview";
import ContentTypeForm from "../../components/content-types/ContentTypeForm";

export function InterviewCreate(props: RouteChildrenProps) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/interviews"}>Interviews</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <ContentTypeForm
        contentType="interview"
        action="create"
        onSubmit={async (values) => {
          const response = await createInterview(values);
          props.history.push(`/interviews/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
