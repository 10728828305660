import React from "react";
import { css } from "styled-components/macro";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BreadcrumbsMUI from "@material-ui/core/Breadcrumbs";

export function Breadcrumbs(props: {
  children: React.ReactNode;
  button?: React.ReactNode;
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;

        a {
          text-decoration: none;
          color: inherit;
        }
      `}
    >
      <BreadcrumbsMUI
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {props.children}
      </BreadcrumbsMUI>
      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        {props.button}
      </div>
    </div>
  );
}
