import React from "react";
import Paddings from "../../components/Paddings";
import { Repeater } from "../../components/Repeater";
import { Column, Row } from "../../helpers/layout";
import { EditorField } from "./EditorField";

const BlockQuestionsAndAnswers = ({ name }: { name: string }) => {
  return (
    <Column>
      <Row>
        <Paddings prefix={name} />
      </Row>
      <Repeater
        name={`${name}.items`}
        label="Rows"
        enableReordering
        addNewLabel="New Row"
        generateNewObject={() => ({ question: null, answer: null })}
      >
        {({ idx }) => (
          <Column key={idx}>
            <EditorField
              name={`${name}.items[${idx}].question`}
              label="Question"
            />
            <EditorField name={`${name}.items[${idx}].answer`} label="Answer" />
          </Column>
        )}
      </Repeater>
    </Column>
  );
};

export default BlockQuestionsAndAnswers;
