import { authorised } from "../../request";
import { Post } from "../../types/Post";

export async function createPost(data: Omit<Post, "id">) {
  const API = authorised();

  return await API.request<Post>({
    method: "POST",
    url: "/api/admin/blog/posts/",
    data,
  });
}
