import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { PostTagForm } from "../../forms/PostTagForm";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createTag } from "../../actions/tag/createTag";
import { RouteChildrenProps } from "react-router";

export function PostTagCreate(props: RouteChildrenProps) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/post-tags"}>Post Tags</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <PostTagForm
        type="create"
        onSubmit={async (values) => {
          const response = await createTag(values);
          props.history.push(`/post-tags/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
