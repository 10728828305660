import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUserState } from "../context/UserContext";
import { RouteChildrenProps } from "react-router";

export const PrivateRoute: React.FC<{
  component: React.FC<RouteChildrenProps<any>>;
  path: string;
  exact?: boolean;
}> = ({ component, ...rest }) => {
  const { isAuthenticated } = useUserState();

  return (
    <Route
      {...rest}
      render={(props: RouteChildrenProps<any>) =>
        isAuthenticated === null ? null : isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export const PublicRoute: React.FC<{
  component: React.FC<RouteChildrenProps<any>>;
  path: string;
}> = ({ component, ...rest }) => {
  const { isAuthenticated } = useUserState();
  return (
    <Route
      {...rest}
      render={(props: RouteChildrenProps<any>) =>
        isAuthenticated === null ? null : isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};
