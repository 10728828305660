import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./helpers/router";
import { Error } from "./pages/Error";
import { Dashboard } from "./pages/Dashboard";

import { PostCreate } from "./pages/post/PostCreate";
import { PostList } from "./pages/post/PostList";
import { PostEdit } from "./pages/post/PostEdit";

import { PostTagCreate } from "./pages/post-tag/PostTagCreate";
import { PostTagList } from "./pages/post-tag/PostTagList";
import { PostTagEdit } from "./pages/post-tag/PostTagEdit";

import { Login } from "./pages/Login";
import { Other } from "./pages/Other";
import { InterviewList } from "./pages/interview/InterviewList";
import { InterviewCreate } from "./pages/interview/InterviewCreate";
import { InterviewEdit } from "./pages/interview/InterviewEdit";
import InterviewTagsList from "./pages/interview-tags/InterviewTagsList";
import { InterviewTagEdit } from "./pages/interview-tags/InterviewTagEdit";
import { InterviewTagCreate } from "./pages/interview-tags/InterviewTagCreate";
import { getSiteConfig } from "utils/multi-auth";

export default function App() {
  useEffect(() => {
    const config = getSiteConfig();
    if (config?.label) {
      document.title = `${config.label} Blog`;
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <PrivateRoute path="/dashboard" component={Dashboard} />

        {/* POSTS */}
        <PrivateRoute path="/posts/create" component={PostCreate} />
        <PrivateRoute path="/posts/:postId" component={PostEdit} />
        <PrivateRoute path="/posts" component={PostList} />

        <PrivateRoute path="/post-tags/create" component={PostTagCreate} />
        <PrivateRoute path="/post-tags/:postTagId" component={PostTagEdit} />
        <PrivateRoute path="/post-tags" component={PostTagList} />

        {/* INTERVIEWS */}
        <PrivateRoute path="/interviews/create" component={InterviewCreate} />
        <PrivateRoute
          path="/interviews/:interviewId"
          component={InterviewEdit}
        />
        <PrivateRoute path="/interviews" component={InterviewList} />
        <PrivateRoute
          path="/interview-tags/create"
          component={InterviewTagCreate}
        />
        <PrivateRoute
          path="/interview-tags/:tagId"
          component={InterviewTagEdit}
        />
        <PrivateRoute path="/interview-tags" component={InterviewTagsList} />

        {/* MISC */}
        <PrivateRoute path="/other" component={Other} />
        <PublicRoute path="/login" component={Login} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
}
