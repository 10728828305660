import React from "react";
import { RouteChildrenProps } from "react-router";
import TagsList from "../../components/content-types/TagsList";

export function PostTagList(props: RouteChildrenProps) {
  return (
    <TagsList
      contentType="post"
      onCreate={() => props.history.push("/post-tags/create")}
    />
  );
}
