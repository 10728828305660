import tinycolor from "tinycolor2";
import { ThemeOptions } from "@material-ui/core/styles/createTheme";
export const primaryColor = "#2B2B2C";
export const secondaryColor = "#D1B988";

const lightenRate = 7.5;
const darkenRate = 15;

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: primaryColor,
      light: "#F3F5FF",
      dark: tinycolor(primaryColor).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondaryColor,
      light: tinycolor(secondaryColor).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondaryColor).darken(darkenRate).toHexString(),
      contrastText: primaryColor,
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
      disabled: "#ccc",
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
  },
};

export default theme;
