import React from "react";
import styled from "styled-components";
import { SelectField } from "../forms/fields/SelectField";
import { paddingOptions } from "../helpers/form";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

const Paddings = ({
  prefix = "",
  vertical = true,
  horizontal = true,
}: {
  prefix?: string;
  vertical?: boolean;
  horizontal?: boolean;
}) => (
  <>
    {vertical && (
      <>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding top"
            name={`${prefix}.padding.top`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding bottom"
            name={`${prefix}.padding.bottom`}
            options={paddingOptions}
          />
        </FieldWrapper>
      </>
    )}
    {horizontal && (
      <>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding left"
            name={`${prefix}.padding.left`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding right"
            name={`${prefix}.padding.right`}
            options={paddingOptions}
          />
        </FieldWrapper>
      </>
    )}
  </>
);

export default Paddings;
