import React, { useState } from "react";
import * as Formik from "formik";
import Typography from "@material-ui/core/Typography";
import styled, { css } from "styled-components/macro";
import Chip from "@material-ui/core/Chip";
import { ModelSelector, AdditionalFields } from "../../modals/ModelSelector";
import { DynamicFormFields } from "../../forms/DynamicForm";
import { ErrorMessage } from "formik";
import pick from "lodash/pick";
import { ReactSortable } from "react-sortablejs";
import { Spacing } from "../../helpers/layout";

type Item = {
  id: number;
  name: string;
};

const ChipContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: ${Spacing.s};
`;

export const FieldError: React.FC<{ name: string }> = props => {
  return (
    <ErrorMessage
      name={props.name}
      render={(errorMessage: any) => {
        if (typeof errorMessage !== "string") return null;

        return (
          <p
            css={css`
              margin: 10px 0 0 14px;
              font-size: 12px;
              color: red;
            `}
          >
            {errorMessage}
          </p>
        );
      }}
    />
  );
};

export function PickField<T extends Item>(props: {
  name: string;
  label: string;
  url: string;
  multiple?: boolean;
  enableSearch?: boolean;
  canCreate?: boolean;
  formFields?: DynamicFormFields;
  pickFields?: string[];
  onCreate?: (values: T) => Promise<void>;
  relatedModel?: string;
  sortable?: boolean;
  additionalFields?: AdditionalFields;
}) {
  const [showSelector, setShowSelector] = useState(false);
  return (
    <Formik.Field
      name={props.name}
      render={({ form, field }: Formik.FieldProps) => {
        const fieldValue = props.relatedModel ? field.value.value : field.value;
        const setValue = (value: any) => {
          if (props.relatedModel) {
            form.setFieldValue(props.name, {
              type: "Related",
              model: props.relatedModel,
              value
            });
          } else {
            form.setFieldValue(props.name, value);
          }
        };

        return (
          <>
            <div>
              <Typography variant="body2">{props.label}</Typography>
              <div
                css={css`
                  margin-top: 10px;
                `}
              >
                <div>
                  {props.multiple && (
                    <ReactSortable
                      list={fieldValue}
                      setList={(value) => {
                        setValue(value);
                      }}
                      disabled={!props.sortable}
                    >
                      {fieldValue.map((item: Item) => {
                        return (
                          <ChipContainer key={item.id}>
                            <Chip
                              label={item.name}
                              onDelete={() => {
                                const value = fieldValue.filter(
                                  (i: Item) => i.id !== item.id
                                );

                                setValue(value);
                              }}
                            />
                          </ChipContainer>
                        );
                      })}
                    </ReactSortable>
                  )}
                  {!props.multiple && fieldValue && (
                    <ChipContainer key={fieldValue.id}>
                      <Chip
                        label={fieldValue.name}
                        onDelete={(i) => {
                          const value = null;

                          if (props.relatedModel) {
                            form.setFieldValue(props.name, {
                              type: "Related",
                              model: props.relatedModel,
                              value,
                            });
                          } else {
                            form.setFieldValue(props.name, value);
                          }
                        }}
                      />
                    </ChipContainer>
                  )}
                  {props.multiple && (
                    <ChipContainer>
                      <Chip
                        label="Add new"
                        onClick={() => {
                          setShowSelector(true);
                        }}
                      />
                    </ChipContainer>
                  )}
                  {!props.multiple && (
                    <ChipContainer>
                      <Chip
                        label={fieldValue ? "Change" : "Select"}
                        onClick={() => {
                          setShowSelector(true);
                        }}
                      />
                    </ChipContainer>
                  )}
                </div>
                <FieldError name={props.name} />
              </div>
            </div>
            <ModelSelector<T>
              open={showSelector}
              handleClose={() => {
                setShowSelector(false);
              }}
              additionalFields={props.additionalFields}
              enableSearch={props.enableSearch}
              label={props.label}
              multiple={props.multiple}
              canCreate={props.canCreate}
              formFields={props.formFields}
              onCreate={props.onCreate}
              url={props.url}
              onSelect={(items: T[]) => {
                const picked = items.map((item) => {
                  return props.pickFields ? pick(item, props.pickFields) : item;
                });

                const value = props.multiple
                  ? [...fieldValue, ...picked]
                  : picked[0];

                setValue(value);
                setShowSelector(false);
              }}
            />
          </>
        );
      }}
    />
  );
}
