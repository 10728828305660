import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { FilePond as Uploader } from "react-filepond";
import { Media } from "types/Media";
import { ListTable } from "components/ListTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Image } from "components/Image";
import request from "utils/request";
import { getApiUrl } from "utils/multi-auth";

export function MediaUploader(props: {
  open: boolean;
  handleClose: () => void;
  onSelect: (file: Media) => void;
}) {
  const [file, setFile] = useState<Media | null>(null);
  const apiUrl = getApiUrl();
  const uploadUrl = `${apiUrl}/paginated/uploads/`;
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <Uploader
          allowMultiple={false}
          server={{
            process: async (
              fieldName: string,
              file,
              metadata,
              load,
              error,
              progress,
              abort
            ) => {
              try {
                const fd = new FormData();
                fd.append("url", file);
                progress(false, 10, 100);
                const res = await request<Media>({
                  path: "/api/uploads/",
                  body: fd,
                  method: "POST",
                });
                progress(false, 100, 100);
                load(res.data);
                setFile(res.data);
              } catch (err: any) {
                error(err);
              }

              return {
                abort: () => {
                  // eslint-disable-next-line no-console
                  console.log("abort");
                  abort();
                },
              };
            },
            load: uploadUrl,
            fetch: uploadUrl,
          }}
        />
        <ListTable<Media>
          url="uploads"
          enableSearch
          head={[
            {
              label: "Image",
              width: "150px",
            },

            {
              label: "Name",
              width: "300px",
            },
            {
              label: "Actions",
              align: "right",
            },
          ]}
        >
          {(result) => {
            return (
              <TableRow key={result.id}>
                <TableCell className="pl-3 fw-normal">
                  <Image file={result} style={{ width: "100%" }} />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      props.onSelect(result);
                      props.handleClose();
                    }}
                  >
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            );
          }}
        </ListTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!file) return;
            props.onSelect(file);
            props.handleClose();
          }}
          color="primary"
          disabled={!file}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
