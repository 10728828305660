import React from "react";
import { BlockTextField } from "./BlockTextField";
import { BlockImageTextField } from "./BlockImageTextField";
import { BlockVideoTextField } from "./BlockVideoTextField";
import { BlockCanvasField } from "./BlockCanvasField";
import { BlockTabsField } from "./BlockTabsField";
import { BlockCarouselField } from "./BlockCarouselField";
import { BlockQuoteField } from "./BlockQuoteField";
import { NewsletterField } from "./NewsletterField";
import { BlockImageGridField } from "./BlockImageGridField";
import { ColumnsField } from "./ColumnsField";
import { BlockFullscreenVideoField } from "./BlockFullscreenVideoField";
import { BlockHrField } from "./BlockHrField";
import { BlockSpacingField } from "./BlockSpacingField";
import { BlockImageField } from "./BlockImageField";
import { BlockEmbedVideoField } from "./BlockEmbedVideoField";
import { BlockDesignerField } from "./BlockDesignerField";
import { BlockLinksField } from "./BlockLinksField";
import BlockQuestionsAndAnswers from "./BlockQuestionsAndAnswers";

export function BlockContentField(props: { name: string; type: string }) {
  if (props.type === "text") {
    return <BlockTextField name={props.name} />;
  }

  if (props.type === "image_text") {
    return <BlockImageTextField name={props.name} />;
  }

  if (props.type === "video_text") {
    return <BlockVideoTextField name={props.name} />;
  }

  if (props.type === "canvas") {
    return <BlockCanvasField name={props.name} />;
  }

  if (props.type === "tabs") {
    return <BlockTabsField name={props.name} />;
  }

  if (props.type === "carousel") {
    return <BlockCarouselField name={props.name} />;
  }

  if (props.type === "quote") {
    return <BlockQuoteField name={props.name} />;
  }

  if (props.type === "newsletter") {
    return <NewsletterField name={props.name} />;
  }

  if (props.type === "image_grid") {
    return <BlockImageGridField name={props.name} />;
  }

  if (props.type === "columns") {
    return <ColumnsField name={props.name} />;
  }

  if (props.type === "fullscreen_video") {
    return <BlockFullscreenVideoField name={props.name} />;
  }

  if (props.type === "hr") {
    return <BlockHrField name={props.name} />;
  }

  if (props.type === "spacing") {
    return <BlockSpacingField name={props.name} />;
  }

  if (props.type === "image") {
    return <BlockImageField name={props.name} />;
  }

  if (props.type === "embedvideo") {
    return <BlockEmbedVideoField name={props.name} />;
  }

  if (props.type === "designer") {
    return <BlockDesignerField name={props.name} />;
  }

  if (props.type === "links") {
    return <BlockLinksField name={props.name} />;
  }

  if (props.type === "qna") {
    return <BlockQuestionsAndAnswers name={props.name} />;
  }

  return <p>This block has no additional settings</p>;
}
