import React, { useState } from "react";
import { FormLeftside, FormRightside, FormRow } from "../../forms/Layout";
import { Column, Row } from "../../helpers/layout";
import { Paper } from "../Paper";
import * as Formik from "formik";
import Typography from "@material-ui/core/Typography";
import { SlugAutoCompleteField } from "../../forms/fields/SlugAutoCompleteField";
import { SlugField } from "../../forms/fields/SlugField";
import { TextField } from "formik-material-ui";
import { BlocksField } from "../../forms/fields/BlocksField";
import { ImageField } from "../../forms/fields/ImageField";
import { SelectField } from "../../forms/fields/SelectField";
import Button from "@material-ui/core/Button";
import { FindInPage } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { ConfirmModal } from "../../modals/ConfirmModal";
import UserPickerField from "../../forms/fields/UserPickerField";
import { createTag } from "../../actions/tag/createTag";
import { Error } from "../Error";
import { PickField } from "../../forms/fields/PickField";
import { PostTag } from "../../types/PostTag";
import { ContentType } from "../../types/common";
import { InterviewFormValues, PostFormValues } from "../../types/Post";
import * as Yup from "yup";
import { css } from "styled-components/macro";
import { capitalize } from "lodash";
import usePreviewUrl from "helpers/usePreviewUrl";

const ContentTypeForm = <T extends ContentType>({
  onDelete,
  contentType,
  action,
  initialValues,
  onSubmit,
}: ContentTypeFormProps<T>) => {
  const [isDeleting] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<null | Fn>(null);
  const [error, setError] = useState<string | null>(null);
  const getPreviewUrl = usePreviewUrl();

  return (
    <Formik.Formik<typeof initialValues>
      initialValues={
        initialValues ||
        (config[contentType].initialValues as unknown as Values<T>)
      }
      validationSchema={config[contentType].validation}
      onSubmit={async (values: typeof initialValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const formattedValues = { ...values };
          if (contentType === "interview") {
            type InterviewRequestValues = InterviewFormValues & {
              artist: number | null;
              judge: number | null;
            };

            const interviewValues = formattedValues as InterviewRequestValues;
            interviewValues.artist =
              interviewValues.interviewee_type === "artist"
                ? interviewValues.interviewee?.value || null
                : null;
            interviewValues.judge =
              interviewValues.interviewee_type === "judge"
                ? interviewValues.interviewee?.value || null
                : null;

            await onSubmit(interviewValues);
          } else {
            await onSubmit(formattedValues as Values<T>);
          }
        } catch (error: any) {
          const message = error?.response?.data
            ? Object.values(error.response.data).join(", ")
            : error.toString();
          setError(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Formik.Form>
          <FormRow>
            <FormLeftside>
              <Column>
                <Paper>
                  <Typography variant="h5">General</Typography>
                  <Formik.FastField
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={SlugAutoCompleteField}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ field_name: "slug" }}
                  />
                  <Formik.FastField
                    name="slug"
                    label="Slug"
                    type="text"
                    fullWidth
                    component={SlugField}
                    margin="normal"
                    variant="outlined"
                  />
                  <Formik.FastField
                    name="description"
                    label="Description"
                    type="text"
                    multiline
                    fullWidth
                    rows="4"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />
                </Paper>
                {contentType === "interview" ? (
                  <>
                    <Paper>
                      <Column>
                        <Typography variant="h5">Interviewee</Typography>
                        <SelectField
                          label="Type"
                          fullWidth
                          name="interviewee_type"
                          options={[
                            { value: "artist", label: "Artist" },
                            { value: "judge", label: "Judge" },
                            { value: "sponsor", label: "Sponsor" },
                          ]}
                        />
                        <Formik.Field
                          name="interviewee"
                          render={({
                            form,
                            field,
                          }: Formik.FieldProps<InterviewFormValues>) =>
                            form.values.interviewee_type !== "sponsor" ? (
                              <UserPickerField
                                userType={form.values.interviewee_type}
                                value={field.value}
                                setValue={(value) =>
                                  form.setFieldValue("interviewee", value)
                                }
                              />
                            ) : null
                          }
                        />
                      </Column>
                    </Paper>
                    {(values as InterviewFormValues).interviewee_type ===
                    "sponsor" ? (
                      <Paper>
                        <Typography variant="h5">Sponsor Details</Typography>
                        <Formik.FastField
                          name="sponsor_name"
                          label="Name"
                          type="text"
                          fullWidth
                          component={TextField}
                          margin="normal"
                          variant="outlined"
                        />
                        <Formik.FastField
                          name="sponsor_location"
                          label="Location"
                          type="text"
                          fullWidth
                          component={TextField}
                          margin="normal"
                          variant="outlined"
                        />
                        {["FACEBOOK", "INSTAGRAM", "LINKEDIN", "WEBSITE"].map(
                          (linkType) => (
                            <Formik.FastField
                              key={linkType}
                              name={`sponsor_${linkType.toLowerCase()}`}
                              label={capitalize(linkType)}
                              type="text"
                              fullWidth
                              component={TextField}
                              margin="normal"
                              variant="outlined"
                            />
                          )
                        )}
                        <ImageField name="sponsor_image" label="Image" />
                      </Paper>
                    ) : null}
                  </>
                ) : null}

                <Paper>
                  <Column>
                    <Typography variant="h5">Miscellaneous</Typography>
                    <Formik.FastField
                      name="reading_time"
                      label="Reading time"
                      type="text"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />
                    <Formik.FastField
                      name="keywords"
                      label="Keywords"
                      type="text"
                      multiline
                      fullWidth
                      rows="4"
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />
                    <PickField<PostTag>
                      name="tags"
                      multiple
                      canCreate
                      enableSearch
                      formFields={[
                        {
                          name: "name",
                          label: "Name",
                          type: "text",
                          required: true,
                        },
                        {
                          name: "slug",
                          label: "Slug",
                          type: "slug",
                          required: true,
                        },
                      ]}
                      onCreate={async (v) => {
                        const response = await createTag(v, contentType);
                        const currentOptions = values?.tags || [];
                        setFieldValue("tags", [
                          ...currentOptions,
                          response.data,
                        ]);
                      }}
                      label="Tags"
                      url={
                        contentType === "interview"
                          ? "interview-tags"
                          : "post-tags"
                      }
                    />
                  </Column>
                </Paper>
                <div
                  css={css`
                    margin-top: 30px;
                  `}
                >
                  <Typography variant="h4">Blocks</Typography>
                </div>
                <BlocksField name="blocks" />
              </Column>
            </FormLeftside>
            <FormRightside>
              <Column>
                <Paper>
                  <Column>
                    <Typography variant="h5">Management</Typography>
                    <SelectField
                      label="Visibility"
                      name="visibility"
                      fullWidth
                      options={[
                        {
                          value: "PUBLIC",
                          label: "Public",
                        },
                        {
                          value: "PRIVATE",
                          label: "Private",
                        },
                        {
                          value: "DRAFT",
                          label: "Draft",
                        },
                      ]}
                    />
                    <Row>
                      <Formik.FastField
                        name="display_author_name"
                        label="Author Name"
                        type="text"
                        fullWidth
                        component={TextField}
                        margin="normal"
                        variant="outlined"
                      />
                    </Row>
                    <Row justify="flex-end">
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          window.open(
                            getPreviewUrl(
                              `preview/${contentType}/${values?.slug}`
                            ),
                            "_blank"
                          );
                        }}
                      >
                        <FindInPage />
                      </Button>
                      {onDelete && (
                        <div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={isDeleting}
                            onClick={() => {
                              setConfirmAction(() => {
                                return onDelete;
                              });
                            }}
                          >
                            {isDeleting ? (
                              <CircularProgress size={25} />
                            ) : (
                              "Delete"
                            )}
                          </Button>
                          {confirmAction !== null && (
                            <ConfirmModal
                              confirmAction={confirmAction}
                              handleClose={() => {
                                setConfirmAction(null);
                              }}
                              open={true}
                              label="Please confirm deletion"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {action === "update" ? "Update" : "Create"}
                        </Button>
                      </div>
                    </Row>
                    {error && <Error text={error} />}
                  </Column>
                </Paper>
                <Paper>
                  <ImageField name="featured_image" label="Featured Image" />
                </Paper>
              </Column>
            </FormRightside>
          </FormRow>
        </Formik.Form>
      )}
    </Formik.Formik>
  );
};

export default ContentTypeForm;

type Values<T extends "post" | "interview"> = T extends "post"
  ? PostFormValues
  : InterviewFormValues;

const postValidation = Yup.object().shape({
  name: Yup.string().required(),
  slug: Yup.string().required(),
  reading_time: Yup.string(),
  description: Yup.string(),
  keywords: Yup.string(),
  visibility: Yup.string().required(),
  blocks: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(),
    })
  ),
});
const postInitialValues = {
  name: "",
  slug: "",
  description: "",
  keywords: "",
  visibility: "DRAFT",
  featured_image: null,
  tags: [],
  blocks: [],
  reading_time: "",
};

const interviewValidation = postValidation.concat(
  Yup.object().shape({
    interviewee_type: Yup.string()
      .oneOf(["artist", "judge", "sponsor"])
      .required(),
  })
);

const interviewInitialValues = {
  ...postInitialValues,
  interviewee_type: "artist",
  interviewee: null,
};

const config = {
  post: {
    validation: postValidation,
    initialValues: postInitialValues,
  },
  interview: {
    validation: interviewValidation,
    initialValues: interviewInitialValues,
  },
};

type Fn = () => void;

type ContentTypeFormProps<T extends ContentType> = {
  onDelete?: Fn;
  contentType: T;
  action: "update" | "create";
  initialValues?: Values<T> | null;
  onSubmit: (values: Values<T>) => Promise<void>;
};
