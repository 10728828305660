import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import PostsIcon from "@material-ui/icons/ArtTrack";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import { withRouter } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useLayout } from "../context/LayoutContext";
import { SidebarLink } from "./SidebarLink";
import classNames from "classnames";
import { SupervisedUserCircleRounded } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sidebarList: {
    marginTop: theme.spacing(6),
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

type MenuItem = {
  label?: string;
  link?: string;
  type?: "title" | "divider";
  icon?: JSX.Element;
  children?: Array<MenuItem>;
};

const structure: Array<MenuItem> = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    type: "divider",
  },
  {
    label: "Posts",
    link: "/posts",
    icon: <PostsIcon />,
  },

  {
    label: "Post Tags",
    link: "/post-tags",
    icon: <LocalOfferIcon />,
  },
  {
    type: "divider",
  },
  {
    label: "Interviews",
    link: "/interviews",
    icon: <SupervisedUserCircleRounded />,
  },
  {
    label: "Interview Tags",
    link: "/interview-tags",
    icon: <LocalOfferIcon />,
  },
];

export function Sidebar() {
  const classes = useStyles();
  const { isDrawerOpened, toggleDrawer } = useLayout();
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    function handleWindowWidthChange() {
      const windowWidth = window.innerWidth;
      const breakpointWidth = 1000;
      const isSmallScreen = windowWidth < breakpointWidth;

      if (isSmallScreen && isPermanent) {
        setPermanent(false);
      } else if (!isSmallScreen && !isPermanent) {
        setPermanent(true);
      }
    }

    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      open={isDrawerOpened}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpened,
        [classes.drawerClose]: !isDrawerOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isDrawerOpened,
          [classes.drawerClose]: !isDrawerOpened,
        }),
      }}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleDrawer()}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <List>
        {structure.map((link, i) => (
          <SidebarLink key={`${link.label}-${i}`} {...link} />
        ))}
      </List>
    </Drawer>
  );
}

export default withRouter(Sidebar);
