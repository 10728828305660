import { authorised } from "../../request";
import { Interview } from "../../types/Post";

export async function deleteInterview(id: string) {
  const API = authorised();

  return await API.request<Interview>({
    method: "DELETE",
    url: `/api/admin/blog/interviews/${id}/`,
  });
}
