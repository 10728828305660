// import { authorised } from "request";
import { Interview } from "types/Post";
import request from "utils/request";

export async function createInterview(data: Omit<Interview, "id">) {
  // // for some reason the authorised function is not working here.
  // // it throws an error saying Object.(...) is not a function.
  // // ¯\_(ツ)_/¯
  // // using the new request util works, so we use it instead.
  // const API = authorised();
  // return await API.request<Interview>({
  //   method: "POST",
  //   url: "/api/admin/blog/interviews/",
  //   data,
  // });

  return await request<Interview>({
    method: "POST",
    path: "/api/admin/blog/interviews/",
    body: data,
  });
}
