import { authorised } from "../../request";
import { ContentType } from "../../types/common";
import { PostTag } from "../../types/PostTag";

export async function deleteTag(id: string, type: ContentType = "post") {
  const API = authorised();

  return await API.request<PostTag>({
    method: "DELETE",
    url: `/api/admin/blog/${type}-tags/${id}/`,
  });
}
