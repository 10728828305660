import React from "react";
import { css } from "styled-components/macro";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PublicIcon from "@material-ui/icons/Public";
import DescriptionIcon from "@material-ui/icons/Description";

export function Visibility({ type }: { type: string }) {
  const labels: {
    [key: string]: string;
  } = {
    PREVIEW: "Preview",
    DRAFT: "Draft",
    PRIVATE: "Private",
    PUBLIC: "Public",
  };
  const icons: {
    [key: string]: JSX.Element;
  } = {
    PREVIEW: <VisibilityIcon />,
    PRIVATE: <VisibilityIcon />,
    DRAFT: <DescriptionIcon />,
    PUBLIC: <PublicIcon />,
  };

  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          margin-right: 5px;
        `}
      >
        {icons[type]}
      </div>
      {labels[type]}
    </div>
  );
}
