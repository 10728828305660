import { SiteKey } from "types/common";
import {
  ACCESS_TOKEN_KEY,
  SITE_CONFIGS,
  SITE_KEY_LOCAL_STORAGE_KEY,
} from "./constants";

export const getSiteConfig = () => {
  const siteKey = getSiteKey(true);
  if (!siteKey || !SITE_CONFIGS[siteKey]) {
    return null;
  }
  return SITE_CONFIGS[siteKey];
};

export const getApiUrl = (key?: SiteKey) => {
  const siteKey = key || getSavedSiteKey();
  if (!siteKey || !SITE_CONFIGS[siteKey]) {
    throw new Error("Unknown or missing site key");
  }
  return SITE_CONFIGS[siteKey].api || "http://localhost:8000";
};

export function getFrontendUrl(path: string) {
  const siteKey = getSiteKey(true);
  if (!siteKey) {
    return "";
  }
  const base = SITE_CONFIGS?.[siteKey]?.web || "http://localhost:3000";
  return `${base}/${path}`;
}

export const getToken = (key?: SiteKey) => {
  const siteKey = key || getSavedSiteKey();
  if (!siteKey || !SITE_CONFIGS[siteKey]) {
    return { token: null, tokenKey: null };
  }
  const tokenKey = `${siteKey}__${ACCESS_TOKEN_KEY}`;
  return { token: localStorage.getItem(tokenKey), tokenKey };
};

/**
 * Get the site key from the URL query string or local storage if not found in the URL
 */
export const getSiteKey = (fallbackToLocalStorage = false) => {
  const siteKeyFromUrl = new URLSearchParams(window.location.search).get(
    "source"
  );

  if (!siteKeyFromUrl && fallbackToLocalStorage) {
    return getSavedSiteKey();
  }

  return siteKeyFromUrl as SiteKey | null;
};

export const getSavedSiteKey = () =>
  localStorage.getItem(SITE_KEY_LOCAL_STORAGE_KEY) as SiteKey | null;
export const saveSiteKey = (siteKey: SiteKey) =>
  localStorage.setItem(SITE_KEY_LOCAL_STORAGE_KEY, siteKey);
export const resetSiteKey = () =>
  localStorage.removeItem(SITE_KEY_LOCAL_STORAGE_KEY);
