import { authorised } from "../../request";
import { Interview } from "../../types/Post";

export async function updateInterview(id: string, data: Omit<Interview, "id">) {
  const API = authorised();

  return await API.request<Interview>({
    method: "PATCH",
    url: `/api/admin/blog/interviews/${id}/`,
    data,
  });
}
