import React from "react";
import { Media } from "types/Media";
import { CSSProperties } from "styled-components";
import noImage from "../static/images/noimage.jpg";
import { getImageUrl } from "../helpers/getImageUrl";

export function Image(props: {
  file: Media | null;
  style?: CSSProperties | undefined;
  width?: number;
}) {
  const src = props.file ? getImageUrl(props.file.url) : noImage;

  return (
    <img
      style={{
        maxWidth: "100%",
        ...props.style,
      }}
      src={src}
      alt={props.file ? `${props.file.id}` : "Image"}
    />
  );
}
