import React from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { createPost } from "../../actions/post/createPost";
import { RouteChildrenProps } from "react-router";
import ContentTypeForm from "../../components/content-types/ContentTypeForm";

export function PostCreate(props: RouteChildrenProps) {
  return (
    <Layout>
      <Breadcrumbs>
        <Link to={"/posts"}>Posts</Link>
        <Typography color="textPrimary">Create</Typography>
      </Breadcrumbs>
      <ContentTypeForm
        action="create"
        contentType="post"
        onSubmit={async (values) => {
          const response = await createPost(values);
          props.history.push(`/posts/${response.data.id}`);
        }}
      />
    </Layout>
  );
}
