import React from "react";
import * as Formik from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import { Column } from "../helpers/layout";
import { ImageField } from "./fields/ImageField";
import { SelectField } from "./fields/SelectField";
import { SlugField } from "./fields/SlugField";
import { SlugAutoCompleteField } from "./fields/SlugAutoCompleteField";

type TextField = {
  type: "text";
};

type SlugField = {
  type: "slug";
};
type SlugAutoCompleteField = {
  type: "text";
};
type NumberField = {
  type: "number";
};
type ImageField = {
  type: "image";
};
type CheckboxField = {
  type: "checkbox";
};
type SelectField = {
  type: "select";
  options: Array<{
    value: string;
    label: string;
  }>;
};

type FieldTypes =
  | TextField
  | SlugField
  | SlugAutoCompleteField
  | NumberField
  | ImageField
  | SelectField
  | CheckboxField;

type Field = FieldTypes & {
  name: string;
  label: string;
  required: boolean;
  defaultValue?: string;
  conditional?: {
    field: string;
    is: string[];
  };
};

export type DynamicFormFields = Array<Field>;

export function DynamicForm(props: {
  formRef: React.MutableRefObject<any>;
  fields: DynamicFormFields;
  onSubmit: (values: any) => Promise<void>;
}) {
  const initialValues = props.fields.reduce((initialValues, field) => {
    const defaulValue = field.type === "checkbox" ? false : "";

    return {
      ...initialValues,
      [field.name]: field.defaultValue || defaulValue,
    };
  }, {});

  const validationSchema = props.fields.reduce((initialValues, field) => {
    let fieldValidationRule = null;

    if (field.type === "text") {
      fieldValidationRule = Yup.string();
    }

    if (field.type === "slug") {
      fieldValidationRule = Yup.string();
    }

    if (field.type === "number") {
      fieldValidationRule = Yup.number();
    }

    if (field.type === "select") {
      fieldValidationRule = Yup.string();
    }

    if (field.type === "image") {
      fieldValidationRule = Yup.object();
    }

    if (field.type === "checkbox") {
      fieldValidationRule = Yup.boolean();
    }

    if (fieldValidationRule && field.required) {
      fieldValidationRule = fieldValidationRule.required();
    }

    if (!fieldValidationRule) return initialValues;

    return {
      ...initialValues,
      [field.name]: fieldValidationRule,
    };
  }, {});

  return (
    <Formik.Formik
      ref={props.formRef}
      initialValues={initialValues}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={async (values: any, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await props.onSubmit(values);
        } catch (error: any) {
          const message =
            error.response && error.response.data
              ? Object.values(error.response.data).join(", ")
              : error.toString();
          console.warn(message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values }) => {
        const isVisible = (field: Field) => {
          if (field.conditional) {
            return field.conditional.is.includes(
              values[field.conditional.field]
            );
          }

          return true;
        };

        return (
          <Formik.Form>
            <Column>
              {props.fields.map((field) => {
                if (!isVisible(field)) return null;

                if (field.type === "text") {
                  return (
                    <Formik.FastField
                      name={field.name}
                      label={field.label}
                      type="text"
                      fullWidth
                      component={SlugAutoCompleteField}
                      margin="normal"
                      variant="outlined"
                      key={field.name}
                    />
                  );
                }
                if (field.type === "slug") {
                  return (
                    <Formik.FastField
                      name={field.name}
                      label={field.label}
                      type="text"
                      fullWidth
                      component={SlugField}
                      margin="normal"
                      variant="outlined"
                      key={field.name}
                    />
                  );
                }
                if (field.type === "number") {
                  return (
                    <Formik.FastField
                      name={field.name}
                      label={field.label}
                      type="number"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                      key={field.name}
                    />
                  );
                }
                if (field.type === "select") {
                  return (
                    <SelectField
                      label={field.label}
                      name={field.name}
                      options={field.options}
                      key={field.name}
                    />
                  );
                }

                if (field.type === "image") {
                  return (
                    <ImageField
                      name={field.name}
                      label={field.label}
                      key={field.name}
                    />
                  );
                }

                if (field.type === "checkbox") {
                  return (
                    <Formik.FastField
                      name={field.name}
                      Label={{ label: field.label }}
                      component={CheckboxWithLabel}
                    />
                  );
                }

                return null;
              })}
            </Column>
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
