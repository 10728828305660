import React from "react";
import { Media } from "types/Media";
import { css } from "styled-components/macro";
import { getImageUrl } from "helpers/getImageUrl";

export function FileInfo(props: { file: Media | null }) {
  if (!props.file) return null;

  return (
    <div
      css={css`
        margin-top: 10px;
      `}
    >
      File:{" "}
      <a
        href={getImageUrl(props.file.url)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {props.file.name}
      </a>
    </div>
  );
}
