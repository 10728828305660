import { getApiUrl } from "../utils/multi-auth";

export const getImageUrl = (url?: string) => {
  if (!url) {
    return "";
  }
  const apiUrl = getApiUrl();
  let newUrl = url;
  if (url[0] === "/") {
    newUrl = `${apiUrl}${url}`;
  }
  return newUrl;
};
