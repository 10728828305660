import { Media } from "./Media";

export enum SocialTypes {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  WEBSITE = "WEBSITE",
}

export type SocialItem = {
  type: SocialTypes;
  url: string;
};
export type CountryChoice = {
  name: string;
  code: string;
};

export enum UserType {
  ADMIN = "ADMIN",
  STAFF = "STAFF",
  JUDGE = "JUDGE",
  ARTIST = "ARTIST",
  BLOGGER = "BLOGGER",
}

export type CommonProfile = {
  profile_image: Media | null;
  about_user: string | null;
  phone_number: string | null;
  social: SocialItem[];
  country: CountryChoice | null;
};

export type User = {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  type: UserType;
  profile: CommonProfile;
};

export interface AdminUser extends User {
  type: UserType.ADMIN | UserType.BLOGGER;
  profile: CommonProfile;
}

export type Me = AdminUser | null;
