import React from "react";
import { RouteChildrenProps } from "react-router";
import { ContentList } from "../../components/content-types/ContentList";

export function InterviewList(props: RouteChildrenProps) {
  return (
    <ContentList
      type="interview"
      onCreate={() => props.history.push(`/interviews/create`)}
    />
  );
}
