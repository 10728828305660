import React, { useState, useRef, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import PlainSelect from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import * as Formik from "formik";

export const FieldError: React.FunctionComponent<{ name: string }> = (
  props
) => {
  return (
    <Formik.ErrorMessage
      name={props.name}
      render={(errorMessage: any) => {
        if (typeof errorMessage !== "string") return null;

        return <p>{errorMessage}</p>;
      }}
    />
  );
};

export function SelectField(props: {
  name: string;
  label: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  fullWidth?: boolean;
}) {
  return (
    <div>
      <Formik.FastField
        name={props.name}
        render={({ form, field }: Formik.FastFieldProps) => {
          return (
            <div>
              <PlainSelectField
                name={props.name}
                label={props.label}
                value={field.value}
                fullWidth={props.fullWidth}
                onChange={(value) => {
                  form.setFieldValue(props.name, value);
                }}
                options={props.options}
              />
              <FieldError name={props.name} />
            </div>
          );
        }}
      />
    </div>
  );
}

export function PlainSelectField<T>(props: {
  name: string;
  label: string;
  value: string;
  onChange: (value: T) => void;
  options: Array<{
    value: string;
    label: string;
  }>;
  fullWidth?: boolean;
}) {
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef<HTMLLabelElement>(null);
  const value = props.value || "";

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <div>
      <FormControl variant="outlined" fullWidth={props.fullWidth}>
        <InputLabel ref={inputLabel} htmlFor={props.name}>
          {props.label}
        </InputLabel>
        <PlainSelect
          name={props.name}
          labelWidth={labelWidth}
          id={props.name}
          fullWidth={props.fullWidth}
          value={value}
          onChange={(e) => {
            props.onChange(e.target.value as T);
          }}
          style={{
            minWidth: 200,
          }}
        >
          {props.options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </PlainSelect>
      </FormControl>
      <FieldError name={props.name} />
    </div>
  );
}
