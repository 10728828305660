import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../components/Layout";
import { Column, Row, Spacing } from "../helpers/layout";
import { css } from "styled-components/macro";
import { RouteChildrenProps } from "react-router-dom";
import { useUserState } from "../context/UserContext";
import { ContentType, Visibility } from "types/common";
import { fetch } from "useAPI";
import { Paper } from "components/Paper";
import { capitalize } from "lodash";

type Stats = Record<
  ContentType,
  Record<Visibility, number> & { total: number }
>;

const Card = ({
  type,
  rows,
  onClick,
}: {
  type: ContentType;
  rows: Stats[keyof Stats];
  onClick: () => void;
}) => {
  return (
    <div
      css={css`
        flex: 1;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      <Paper>
        <Column>
          <div
            css={css`
              border-bottom: 1px solid #eee;
              padding-bottom: ${Spacing.m};
              display: flex;
              justify-content: space-between;
            `}
          >
            <Typography variant="h5">{`${capitalize(type)}s`}</Typography>
            <Typography variant="h5">{rows.total}</Typography>
          </div>
          {Object.entries(rows).map(([key, count]) =>
            key !== "total" ? (
              <Row justify="space-between" key={key}>
                <Typography>{capitalize(key)}</Typography>
                <Typography>{count}</Typography>
              </Row>
            ) : null
          )}
        </Column>
      </Paper>
    </div>
  );
};

export function Dashboard(props: RouteChildrenProps) {
  const [stats, setStats] = useState<Stats | null>(null);
  const { userprofile } = useUserState();

  const navTo = (path: string) => {
    props.history.push(path);
  };

  useEffect(() => {
    void (async () => {
      const { data: newStats } = await fetch<Stats>("stats");
      setStats(newStats);
    })();
  }, []);

  return (
    <Layout>
      <Column gutter={Spacing.l}>
        <Typography variant="h4" color="primary">
          Hello, {userprofile?.first_name || "Admin"}
        </Typography>
        {stats && (
          <Row>
            <Card
              type="post"
              rows={stats.post}
              onClick={() => navTo("/posts")}
            />
            <Card
              type="interview"
              rows={stats.interview}
              onClick={() => navTo("/interviews")}
            />
          </Row>
        )}
      </Column>
    </Layout>
  );
}
