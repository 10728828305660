import React, { useEffect, useMemo, useRef, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { throttle } from "lodash";
import { fetch } from "../../useAPI";

type Option = {
  label: string;
  value: string | number;
};

type PickerFieldProps = {
  value: Option | null;
  setValue: (value: Option | null) => void;
  userType: "artist" | "judge";
};

const UserPickerField = ({ value, setValue, userType }: PickerFieldProps) => {
  const [inputValue, setInputValue] = useState(value?.label || "");
  const [options, setOptions] = useState<Option[]>(value ? [value] : []);
  const firstRender = useRef(true);

  const getUsers = useMemo(
    () =>
      throttle(
        (
          input: { query: string; type: "artist" | "judge" },
          callback: (results?: Option[]) => void
        ) => {
          fetch<Option[]>(
            `interviewees/?role=${input.type}&query=${input.query}`
          )
            .then((res) => {
              if (res?.data) {
                callback(res.data);
              }
            })
            .catch(() => null);
        },
        500,
        { leading: false }
      ),
    []
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setOptions([]);
      setInputValue("");
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getUsers({ query: inputValue, type: userType }, (results?: Option[]) => {
      if (active) {
        let newOptions: Option[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, userType, getUsers]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_, newValue: Option | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={`Select ${userType}`}
          fullWidth
        />
      )}
    />
  );
};

export default UserPickerField;
