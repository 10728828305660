import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../../components/Layout";
import { ListTable } from "../../components/ListTable";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Paper } from "../../components/Paper";
import { PostTag } from "../../types/PostTag";
import { AddCircle } from "@material-ui/icons";
import { capitalize } from "lodash";

const TagsList = ({
  contentType,
  onCreate,
}: {
  contentType: "post" | "interview";
  onCreate: () => void;
}) => {
  return (
    <Layout>
      <Breadcrumbs
        button={
          <Button
            onClick={onCreate}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<AddCircle />}
          >
            Create
          </Button>
        }
      >
        <Typography color="textPrimary">
          {capitalize(contentType)} Tags
        </Typography>
      </Breadcrumbs>
      <Paper>
        <ListTable<PostTag>
          url={`${contentType}-tags`}
          enableSearch
          head={[
            { label: "Name" },
            { label: "Slug" },
            { label: "Actions", align: "right" },
          ]}
        >
          {(result) => (
            <TableRow key={result.id}>
              <TableCell className="pl-3 fw-normal">{result.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{result.slug}</TableCell>
              <TableCell align="right">
                <Link to={`/${contentType}-tags/${result.id}`}>
                  <Fab color="primary" aria-label="edit" size="small">
                    <EditIcon />
                  </Fab>
                </Link>
              </TableCell>
            </TableRow>
          )}
        </ListTable>
      </Paper>
    </Layout>
  );
};

export default TagsList;
