import React, { useState, useEffect } from "react";
import { Canvas, CanvasComponent, Align } from "types/Canvas";
import { Image } from "./Image";
import { css } from "styled-components/macro";
import { CanvasItem, sanitizePosition } from "./CanvasItem";
import { TextEditor } from "../modals/TextEditor";
import uuidv4 from "uuid/v4";
import { getSpacing } from "./CanvasPreview";

function getAxisMultiplier(align: Align) {
  if (align === "top-right") {
    return {
      x: -1,
      y: 1,
    };
  }
  if (align === "right-center") {
    return {
      x: -1,
      y: 1,
    };
  }

  if (align === "bottom-left") {
    return {
      x: 1,
      y: -1,
    };
  }
  if (align === "bottom-center") {
    return {
      x: 1,
      y: -1,
    };
  }

  if (align === "bottom-right") {
    return {
      x: -1,
      y: -1,
    };
  }

  return {
    x: 1,
    y: 1,
  };
}

export function CanvasEditor(props: {
  canvas: Canvas;
  onChangeComponents: (components: CanvasComponent[]) => void;
}) {
  const [initialised, setInitialised] = useState(false);

  const { image, padding, align, bg_color, bg_opacity, components } =
    props.canvas;
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showTextField, setShowTextField] = useState<null | CanvasComponent>(
    null
  );

  useEffect(() => {
    setInitialised(true);
  }, []);

  useEffect(() => {
    function flushPositions() {
      const newComponents = components.map((component) => {
        return {
          ...component,
          position: {
            x: 0,
            y: 0,
          },
        };
      });

      props.onChangeComponents(newComponents);
    }

    if (initialised) {
      flushPositions();
    }
  }, [align, initialised, components, props]);

  const updateComponent = (data: CanvasComponent) => {
    props.onChangeComponents(
      components.map((component) => {
        if (component.id !== data.id) return component;

        return data;
      })
    );
  };

  const deleteComponent = (data: CanvasComponent) => {
    const filtered = components.filter((component) => data.id !== component.id);

    props.onChangeComponents(filtered);
  };

  const addComponent = (data: CanvasComponent) => {
    props.onChangeComponents([...components, data]);
  };

  return (
    <React.Fragment>
      <div
        css={css`
          border: 1px solid gray;
          position: relative;
          width: 1791px;
          zoom: 0.51;
          ${padding &&
          css`
            padding-top: ${getSpacing(padding.top)};
            padding-bottom: ${getSpacing(padding.bottom)};
            padding-left: ${getSpacing(padding.left)};
            padding-right: ${getSpacing(padding.right)};
          `};
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: ${bg_color};
            `}
          ></div>
          <Image
            file={image.value}
            style={{
              position: "relative",
              display: "block",
              width: "100%",
              opacity: bg_opacity,
            }}
          />

          {/* Clickable area to add new items */}

          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              cursor: crosshair;
            `}
            onMouseMove={(e) => {
              const axisMultiplier = getAxisMultiplier(align);
              const target = e.nativeEvent.target as HTMLDivElement;
              const targetWidth = target.offsetWidth;
              const targetHeight = target.offsetHeight;
              const nativeOffetX = (e.nativeEvent.offsetX * 100) / 51;
              const nativeOffetY = (e.nativeEvent.offsetY * 100) / 51;

              const offsetX =
                axisMultiplier.x === -1
                  ? targetWidth - nativeOffetX
                  : nativeOffetX;
              const offsetY =
                axisMultiplier.y === -1
                  ? targetHeight - nativeOffetY
                  : nativeOffetY;

              setPosition({
                x: offsetX * axisMultiplier.x,
                y: offsetY * axisMultiplier.y,
              });
            }}
            onClick={(e) => {
              e.stopPropagation();

              const target = e.nativeEvent.target as HTMLDivElement;
              const targetHeight = target.offsetHeight;
              const pos = sanitizePosition(position, align, targetHeight);

              const newItem: CanvasComponent = {
                id: uuidv4(),
                type: "text",
                text: "",
                position: pos,
                size: {
                  width: 300,
                  height: 100,
                },
              };

              addComponent(newItem);
              setShowTextField(newItem);
            }}
          ></div>

          {/* Render existing items */}
          {components.map((component) => {
            return (
              <CanvasItem
                key={`component--${component.id}`}
                align={align}
                item={component}
                onDelete={deleteComponent}
                onDrag={updateComponent}
                onResize={updateComponent}
                onEdit={(item) => {
                  setShowTextField(item);
                }}
              />
            );
          })}
        </div>
        {showTextField && (
          <TextEditor
            open={true}
            handleClose={() => {
              setShowTextField(null);
            }}
            initialValues={{
              text: showTextField.text,
            }}
            onSubmit={({ text }) => {
              updateComponent({
                ...showTextField,
                text,
              });
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
